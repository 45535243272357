<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        {{ $t("Linked Pages") }}
      </v-card-title>
      <v-card-text>
        <h3 v-if="getPagesNotAssigned().length > 0" class="mb-3 mt-3">
          {{ $t("Subpages") }}
        </h3>
        <template v-for="(subPage, index) in page.SubPages">
          <v-row no-gutters :key="index">
            <v-col xs="9" sm="9" lg="11">
              <h4 class="mt-3 page-label">
                {{ getPageNameFromId(subPage.$oid) }}
              </h4>
            </v-col>
            <v-col xs="3" sm="3" lg="1">
              <v-btn icon color="red" @click="removeSubPage(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-select
                v-if="getPagesNotAssigned().length > 0"
                :items="getPagesNotAssigned()"
                :label="$t('Select a page')"
                item-value="_id.$oid"
                item-text="NameCurrentLanguage"
                v-model="pageToAdd"
                single-line
                class="add-item-select mr-3"
              ></v-select>
              <v-btn
                color="green"
                class="
                  mt-3
                  mb-3
                  ml-0
                  edit-page-action-button
                  dropdown-action-button
                "
                dark
                @click="addSubPage()"
                v-if="pageToAdd && getPagesNotAssigned().length > 0"
              >
                {{ $t("Add page") }}
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="page.RepeatingContent">
          <v-col xs="12" sm="12" lg="12">
            <h3 class="mt-3">{{ $t("Shared pages") }}</h3>
          </v-col>
          <v-col xs="12" sm="10" lg="11">
            <template v-for="(linkedPage, index) in page.LinkedPages">
              <v-row :key="index">
                <v-col xs="9" sm="9" lg="11">
                  <h4 class="mt-3 page-label">
                    {{ getWebsitePageLabelFromPageId(linkedPage.$oid) }}
                  </h4>
                </v-col>
                <v-col xs="3" sm="3" lg="1">
                  <v-btn icon color="red" @click="removeLinkedPage(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-col>
          <v-col xs="12" sm="10" lg="11">
            <v-select
              v-if="otherWebsites"
              :items="otherWebsites"
              @change="getPagesForSelectedWebsite"
              :label="$t('Select a module')"
              item-text="Name"
              v-model="selectedLinkedWebsite"
              single-line
              class="add-item-select mr-3"
              item-value="_id"
            ></v-select>

            <v-select
              v-if="getSharedPagesNotAssigned().length > 0"
              :items="getSharedPagesNotAssigned()"
              :label="$t('Select a page')"
              v-model="selectedLinkedPage"
              single-line
              item-text="Name"
              item-value="_id"
              class="add-item-select mr-3"
            >
              <template slot="selection" slot-scope="data">
                <div class="input-group__selections__comma">
                  {{ data.item.Name[currentLanguage] }}
                </div>
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.Name[currentLanguage] }}
              </template>
            </v-select>

            <v-btn
              color="green"
              class="
                mt-3
                mb-3
                ml-0
                edit-page-action-button
                dropdown-action-button
              "
              dark
              @click="addLinkedPage()"
              v-if="
                selectedLinkedPage && getSharedPagesNotAssigned().length > 0
              "
            >
              {{ $t("Add page") }}
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: [Array, Object],
    },
    pages: {
      type: [Array, Object],
    },
    websites: {
      type: [Array, Object],
    },
    otherWebsitesProps: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      pageToAdd: null,
      selectedLinkedWebsite: null,
      selectedLinkedPage: null,
      selectedLinkedWebsitePages: null,
      otherWebsites: this.otherWebsitesProps,
    };
  },

  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  methods: {
    getPagesNotAssigned() {
      var pages = [];

      if (this.pages && this.page) {
        for (var i = 0; i < this.pages.length; i++) {
          var subpageAssignedToPage = false;
          for (var ii = 0; ii < this.page.SubPages.length; ii++) {
            if (this.pages[i]._id.$oid == this.page.SubPages[ii].$oid) {
              subpageAssignedToPage = true;
            }
          }

          var currentPageId = "";
          if (this.page._id != undefined) {
            currentPageId = this.page._id.$oid;
          }

          if (
            !subpageAssignedToPage &&
            this.pages[i]._id.$oid != currentPageId
          ) {
            this.pages[i].NameCurrentLanguage =
              this.pages[i].Name[this.currentLanguage];
            pages[pages.length] = this.pages[i];
          }
        }
      }
      return pages;
    },
    getSharedPagesNotAssigned() {
      var pages = [];
      if (this.selectedLinkedWebsite && this.page) {
        for (var i = 0; i < this.selectedLinkedWebsitePages.length; i++) {
          var linkedPageAssignedToPage = false;
          for (var ii = 0; ii < this.page.LinkedPages.length; ii++) {
            if (
              this.selectedLinkedWebsitePages[i]._id.$oid ==
              this.page.LinkedPages[ii].$oid
            ) {
              linkedPageAssignedToPage = true;
            }
          }

          var currentPageId = "";
          if (this.page._id != undefined) {
            currentPageId = this.page._id.$oid;
          }

          if (
            !linkedPageAssignedToPage &&
            this.selectedLinkedWebsitePages[i]._id.$oid != currentPageId
          ) {
            this.selectedLinkedWebsitePages[i].NameCurrentLanguage =
              this.selectedLinkedWebsitePages[i].Name[this.currentLanguage];
            pages[pages.length] = this.selectedLinkedWebsitePages[i];
          }
        }
      }
      return pages;
    },
    getPagesForSelectedWebsite(id) {
      this.selectedLinkedWebsitePages = this.websites
        .find((w) => w._id.$oid == id.$oid)
        .Pages.filter((p) => p.RepeatingContent);
    },
    getPageNameFromId(id) {
      let pageName = "";
      if (this.pages) {
        for (var i = 0; i < this.pages.length; i++) {
          if (this.pages[i]._id.$oid == id) {
            pageName = this.pages[i].Name[this.currentLanguage];
          }
        }
      }
      return pageName;
    },
    removeSubPage(index) {
      this.page.SubPages.splice(index, 1);
    },
    addSubPage() {
      if (this.pageToAdd) {
        this.page.SubPages.push({
          $oid: this.pageToAdd,
        });
      }
    },
    getWebsitePageLabelFromPageId(id) {
      if (this.websites) {
        for (var i = 0; i < this.websites.length; i++) {
          for (var ii = 0; ii < this.websites[i].Pages.length; ii++) {
            if (this.websites[i].Pages[ii]._id.$oid == id) {
              return (
                this.websites[i].Name +
                " - " +
                this.websites[i].Pages[ii].Name[this.currentLanguage]
              );
            }
          }
        }
      }

      return "";
    },
    removeLinkedPage(index) {
      this.page.LinkedPages.splice(index, 1);
    },
    addLinkedPage() {
      if (this.selectedLinkedPage) {
        this.page.LinkedPages.push({
          $oid: this.selectedLinkedPage.$oid,
        });
      }
    },
  },
};
</script>

<style scoped>
.page-label {
  color: #666;
}
</style>